import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../css/Instruction.css";
import screenfull from "screenfull";
import { ProctorContext } from "./CombineTest/TestProctor/ProctorContext";

const Instruction = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [testInfo, setTestInfo] = useState(null);
  const [error, setError] = useState(null);
  const { startProctoring } = useContext(ProctorContext);
  const [proctoringInitialized, setProctoringInitialized] = useState(false);

  useEffect(() => {
    try {
      const storedTestInfo = localStorage.getItem('testInfo');
      const email = localStorage.getItem('email');
      const testId = localStorage.getItem('testId');

      if (!storedTestInfo || !email || !testId) {
        throw new Error('Missing required test information');
      }

      const parsedTestInfo = JSON.parse(storedTestInfo);
      setTestInfo(parsedTestInfo);
    } catch (error) {
      console.error('Error loading test information:', error);
      setError(error.message);
      toast.error('Error loading test information');
      setTimeout(() => navigate('/attempt-test'), 2000);
    }
  }, [navigate]);

  const initializeProctoring = async () => {
    try {
      const testInfo = JSON.parse(localStorage.getItem('testInfo'));
      await startProctoring(testInfo.testId);
      setProctoringInitialized(true);
      return true;
    } catch (error) {
      console.error('Error initializing proctoring:', error);
      if (error.name === 'NotAllowedError') {
        toast.error('Camera and screen sharing access is required for this test');
      }
      return false;
    }
  };

  const startExamBtn = async () => {
    if (!testInfo) {
      toast.error('Test information not available');
      return;
    }
    
    // Initialize proctoring before showing modal
    const proctoringSuccess = await initializeProctoring();
    if (proctoringSuccess) {
      setShowModal(true);
    }
  };

  const handleConfirmation = async () => {
    setShowModal(false);
    
    if (!proctoringInitialized) {
      toast.error('Proctoring must be initialized before starting the test');
      return;
    }

    try {
      if (screenfull.isEnabled) {
        await screenfull.request(document.documentElement);
      }
      
      navigate("/section-selection");
    } catch (error) {
      console.error('Error starting test:', error);
      toast.error('Failed to start test. Please try again.');
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <p>Redirecting to attempt test page...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="instruction-page">
      <header>
        <h1>Online Examination Guidelines</h1>
      </header>
      <main>
        <section>
          <h2>Important Instructions</h2>
          <p>1. Ensure stable Internet connectivity.</p>
          <p>2. Using a laptop or desktop is highly recommended.</p>
          <p>
            3. Make sure your device is fully charged. Arrange a power bank or
            UPS for uninterrupted power supply.
          </p>
          <p>4. Have sufficient data in your Internet plan.</p>
          <p>5. Close all other browsers/tabs before starting the exam.</p>
          <p>
            6. Do not switch to any other window/tab during the exam to avoid
            disqualification.
          </p>
          <p>
            7. Avoid receiving calls if taking the exam on mobile, as it will be
            treated as switching windows.
          </p>
          <p>8. Use an Ad Blocker to avoid unwanted pop-ups.</p>
          <p>
            9. Clear your browser cache, history, and temporary files before the
            exam.
          </p>
        </section>
        <section className="notes">
          <h2>Notes</h2>
          <p>1. Use web browsers like Mozilla Firefox or Google Chrome.</p>
          <p>
            2. Avoid using the back button or closing the browser during the
            exam.
          </p>
          <p>
            <strong>
              3. You should start exam on the time provided otherwise if you
              join late then you can attempt the test for remaining time only.
            </strong>
          </p>
          <p>4. Use of Ctrl, Alt, Tab, Shift keys will generate warning.</p>
          <p>
            5. There will be only one warning after that the test will auto
            submit.
          </p>
        </section>
      </main>
      <footer>
        <button onClick={startExamBtn} className="start-btn">
          Start Test
        </button>
      </footer>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">
              Close Other Tabs and Applications
            </h2>
            <p className="mb-4">
              Please make sure to close all other browser tabs and applications
              before starting the test.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleConfirmation}
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
              >
                I have closed them
              </button>
              <button
                onClick={closeModal}
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Instruction;
