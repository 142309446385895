import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { ProctorContext } from "./ProctorContext";
import FloatingVideo from "./FloatingVideo";
import * as tf from "@tensorflow/tfjs";
import * as faceDetection from "@tensorflow-models/face-detection";
import { useTestSubmission } from './TestSubmissionContext';

const ProctorVideoWrapper = ({ hideVideo = false }) => { 
  const context = useContext(ProctorContext);
  const { submitTest } = useTestSubmission();
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;
  const videoRef = useRef(null);
  const detectorRef = useRef(null);
  const noFaceCounterRef = useRef(0);
  const checkIntervalRef = useRef(null);
  const lastWarningRef = useRef(0);
  const WARNING_COOLDOWN = 3000;
  const [isDetectorReady, setIsDetectorReady] = useState(false);
  const isFaceDetectedRef = useRef(true);
  const canIncrementCounterRef = useRef(true);
  const MAX_VIOLATIONS = 5;

  useEffect(() => {
    const initializeFaceDetector = async () => {
      try {
        await tf.setBackend("webgl");
        const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
        const detectorConfig = {
          runtime: "tfjs",
          maxFaces: 1,
          modelType: "short",
        };
        detectorRef.current = await faceDetection.createDetector(
          model,
          detectorConfig
        );
        setIsDetectorReady(true);
        console.log("Face detector initialized");
      } catch (error) {
        console.error("Error initializing face detector:", error);
      }
    };

    initializeFaceDetector();

    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, []);

  const checkFace = async () => {
    if (!detectorRef.current || !videoRef.current || !videoRef.current.srcObject) return;

    try {
      if (videoRef.current.readyState !== 4) return;

      const faces = await detectorRef.current.estimateFaces(videoRef.current);
      const now = Date.now();

      if (faces.length === 0) {
        if (isFaceDetectedRef.current && canIncrementCounterRef.current) {
          noFaceCounterRef.current++;
          console.log('No face detected - Violation count:', noFaceCounterRef.current);
          canIncrementCounterRef.current = false;

          if (now - lastWarningRef.current > WARNING_COOLDOWN) {
            toast.warning('Please stay in front of the camera', {
              toastId: 'face-detection-warning',
              autoClose: 3000,
            });
            lastWarningRef.current = now;
          }

          if (noFaceCounterRef.current >= MAX_VIOLATIONS) {
            clearInterval(checkIntervalRef.current);
            toast.error('Maximum face detection violations reached. Test will be submitted.', {
              toastId: 'max-violations',
              autoClose: 5000,
            });
            await submitTest('FACE_DETECTION_VIOLATION');
          }
        }
        isFaceDetectedRef.current = false;
      } else {
        if (!isFaceDetectedRef.current) {
          console.log('Face detected again - Ready for next violation count');
          canIncrementCounterRef.current = true;
        }
        isFaceDetectedRef.current = true;
      }
    } catch (error) {
      console.error('Face detection error:', error);
    }
  };

  useEffect(() => {
    if (!context?.isProctoring || !context?.stream || !isDetectorReady) return;

    if (videoRef.current && context.stream) {
      videoRef.current.srcObject = context.stream;
      videoRef.current.play().catch(err => console.error("Error playing video:", err));
    }

    checkIntervalRef.current = setInterval(checkFace, 1000);

    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [context?.isProctoring, context?.stream, isDetectorReady]);

  if (!context || !context.isProctoring) {
    return null;
  }

  return (
    <>
      {!hideVideo && (
        <FloatingVideo
          onClose={() => context.setShowVideo(false)}
          stream={context.stream}
        />
      )}
      <video
        ref={videoRef}
        style={{ display: "none" }}
        autoPlay
        playsInline
        muted
        width="640"
        height="480"
      />
    </>
  );
};

export default ProctorVideoWrapper;