// SecurityProviderContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTestSubmission } from './TestSubmissionContext';

export const SecurityContext = createContext(null);

// Define violation types and their limits
const SECURITY_VIOLATIONS = {
  TAB_SWITCH: {
    limit: 5,
    message: 'Tab/Window switching detected',
    submissionReason: 'TAB_SWITCH_VIOLATION'
  },
  COPY_PASTE: {
    limit: 5,
    message: 'Copy/Paste attempt detected',
    submissionReason: 'COPY_PASTE_VIOLATION'
  },
  KEYBOARD_SHORTCUT: {
    limit: 5,
    message: 'Keyboard shortcut detected',
    submissionReason: 'KEYBOARD_SHORTCUT_VIOLATION'
  },
  RIGHT_CLICK: {
    limit: 5,
    message: 'Right-click detected',
    submissionReason: 'RIGHT_CLICK_VIOLATION'
  }
};

export const SecurityProvider = ({ children }) => {
  const navigate = useNavigate();
  const { submitTest } = useTestSubmission();
  const [violations, setViolations] = useState(
    Object.keys(SECURITY_VIOLATIONS).reduce((acc, key) => ({ ...acc, [key]: 0 }), {})
  );

  const handleViolation = async (type) => {
    const violationConfig = SECURITY_VIOLATIONS[type];
    
    setViolations(prev => {
      const newCount = prev[type] + 1;
      
      if (newCount >= violationConfig.limit) {
        // Submit test with specific violation reason
        submitTest(violationConfig.submissionReason);
        return prev; // Don't update state since we're submitting
      }

      // Show warning toast with remaining attempts
    //   toast.warning(
    //     `${violationConfig.message} - Warning ${newCount}/${violationConfig.limit}`, 
    //     {
    //       toastId: `security-violation-${type}`,
    //       autoClose: 3000
    //     }
    //   );
    console.log(`${violationConfig.message} - Warning ${newCount}/${violationConfig.limit}`)
      
      return { ...prev, [type]: newCount };
    });
  };

  // Tab/Window visibility detection
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleViolation('TAB_SWITCH');
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  // Copy/Paste prevention
  useEffect(() => {
    const handleCopyPaste = (e) => {
      e.preventDefault();
      handleViolation('COPY_PASTE');
    };

    const events = ['copy', 'paste', 'cut'];
    events.forEach(event => document.addEventListener(event, handleCopyPaste));

    return () => events.forEach(event => 
      document.removeEventListener(event, handleCopyPaste)
    );
  }, []);

  // Right-click prevention
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      handleViolation('RIGHT_CLICK');
    };

    document.addEventListener('contextmenu', handleContextMenu);
    return () => document.removeEventListener('contextmenu', handleContextMenu);
  }, []);

  // Keyboard shortcuts prevention
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Common shortcut combinations
      const shortcutKeys = {
        a: 'Select all',
        p: 'Print',
        s: 'Save',
        u: 'View source',
        h: 'History',
        j: 'Downloads',
        w: 'Close tab',
        r: 'Reload',
        f: 'Find',
        '+': 'Zoom in',
        '-': 'Zoom out'
      };

      if ((e.ctrlKey || e.metaKey) && shortcutKeys[e.key]) {
        e.preventDefault();
        handleViolation('KEYBOARD_SHORTCUT');
      }

      // Prevent Alt+Tab
      if (e.altKey && e.key === 'Tab') {
        e.preventDefault();
        handleViolation('TAB_SWITCH');
      }

      // Prevent F11 (fullscreen toggle)
      if (e.key === 'F11') {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Browser navigation prevention
  useEffect(() => {
    const preventNavigation = (e) => {
      e.preventDefault();
      handleViolation('TAB_SWITCH');
      // Push current state back to prevent navigation
      window.history.pushState(null, null, window.location.href);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', preventNavigation);

    return () => window.removeEventListener('popstate', preventNavigation);
  }, []);

  return (
    <SecurityContext.Provider value={{ violations }}>
      {children}
    </SecurityContext.Provider>
  );
};

// Custom hook for using security context
export const useSecurity = () => {
  const context = useContext(SecurityContext);
  if (!context) {
    throw new Error('useSecurity must be used within a SecurityProvider');
  }
  return context;
};