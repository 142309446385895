import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Box, AppBar, Toolbar, Typography, Button } from "@mui/material";
import {
  Assignment as AssignmentIcon,
  Person as PersonIcon,
  Timer as TimerIcon,
} from "@mui/icons-material";

import MCQView from "./MCQView";
import CodingView from "./CodingView";
import QuestionNavigation from "./QuestionNavigation";
import FullScreenManager from "./FullScreenManager";
import Security from "./Security";
import ProctorVideoWrapper from "./TestProctor/ProctorVideoWrapper";
import { SecurityProvider, useSecurity } from './TestProctor/SecurityProviderContext';


const CombineTestPage = () => {
  const { violations } = useSecurity();
  const navigate = useNavigate();
  const location = useLocation();
  const { sectionData } = location.state || {};

  const [currentSection, setCurrentSection] = useState(
    sectionData?.sectionInfo || null
  );
  // const [sectionTimeRemaining, setSectionTimeRemaining] = useState(
  //   sectionData?.sectionInfo.sectionDuration * 60 || 0
  // );
  const [testInfo, setTestInfo] = useState(sectionData?.testInfo || null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({
    type: "mcq",
    index: 0,
  });
  const [loading, setLoading] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [isFullScreen, setIsFullScreen] = useState(
    !!document.fullscreenElement
  );
  const [languages, setLanguages] = useState([]);
  const [sectionTimeRemaining, setSectionTimeRemaining] = useState(() => {
    const startTime = localStorage.getItem("currentSectionStartTime");
    const duration = localStorage.getItem("currentSectionDuration");
    const lastUpdated = localStorage.getItem("sectionLastUpdated");
    const sectionId = localStorage.getItem("currentSectionId");

    if (
      startTime &&
      duration &&
      lastUpdated &&
      sectionId === sectionData?.sectionInfo.sectionId
    ) {
      const elapsedSeconds = Math.floor(
        (Date.now() - parseInt(lastUpdated)) / 1000
      );
      return Math.max(0, parseInt(duration)); // Use stored duration directly
    }

    // If no stored time or different section, use section duration
    return sectionData?.sectionInfo.sectionDuration * 60 || 0;
  });

  const getContentStyle = (isNavOpen) => ({
    width: isNavOpen ? "calc(100% - 300px)" : "100%", // 300px is the navigation width
    transition: "width 0.3s ease",
    marginRight: isNavOpen ? "300px" : "0",
  });

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (!sectionData) {
      // toast.error("No section data found");
      console.error("No section data found");
      navigate("/section-selection");
      return;
    }

    loadSectionData(sectionData.sectionInfo);
  }, [sectionData]);

  const loadSectionData = async (sectionInfo) => {
    try {
      const response = await axios.post(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/participant/test/fetch-section-data`,
        {
          sectionId: sectionInfo.sectionId,
          allowedLanguagesId: sectionInfo.allowedLanguages.map(
            (l) => l.languageId
          ),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const {
          mcqQuestions,
          codingQuestions,
          languages: fetchedLanguages,
        } = response.data.data;
        setLanguages(fetchedLanguages);

        // Add sectionId to each question
        const mcqWithSection = mcqQuestions.map((q) => ({
          ...q,
          sectionId: sectionInfo.sectionId,
        }));

        const codingWithSection = codingQuestions.map((q) => ({
          ...q,
          sectionId: sectionInfo.sectionId,
        }));

        const organizedQuestions = organizeQuestions(
          mcqWithSection,
          codingWithSection
        );
        setQuestions(organizedQuestions);

        // Initialize answeredQuestions based on previous attempts
        const initialAnsweredQuestions = {};
        mcqQuestions.forEach((q) => {
          if (q.isAnswered) {
            initialAnsweredQuestions[q.mcq_question_id] = true;
          }
        });
        codingQuestions.forEach((q) => {
          if (q.codeSubmitted) {
            initialAnsweredQuestions[q.question_id] = true;
          }
        });
        setAnsweredQuestions(initialAnsweredQuestions);

        // Set initial question
        if (organizedQuestions.length > 0) {
          setCurrentQuestion({
            type: organizedQuestions[0].type,
            index: 0,
          });
        }
      }
    } catch (error) {
      console.error("Error loading section data:", error);
      // toast.error("Failed to load section data");
    } finally {
      setLoading(false);
    }
  };

  const organizeQuestions = (mcqQuestions, codingQuestions) => {
    // First, sort MCQ questions by order_no
    const sortedMcq = mcqQuestions
      .map((q) => ({ ...q, type: "mcq" }))
      .sort((a, b) => a.order_no - b.order_no);

    // Then, sort coding questions by order_no
    const sortedCoding = codingQuestions
      .map((q) => ({ ...q, type: "coding" }))
      .sort((a, b) => a.order_no - b.order_no);

    // Return combined array with MCQ questions first, then coding questions
    return [...sortedMcq, ...sortedCoding];
  };

  useEffect(() => {
    const fetchPreviousAttempts = async () => {
      try {
        const testId = localStorage.getItem("testId");
        const email = localStorage.getItem("email");
        const testInfo = JSON.parse(localStorage.getItem("testInfo"));

        // Fetch MCQ attempts
        const mcqResponse = await axios.get(
          `${
            import.meta.env.VITE_BASE_URL
          }/api/participant/attempt-mcq-test/fetch-options/${email}/${testId}`
        );

        const attemptedQuestions = {};

        // Mark MCQ questions
        if (mcqResponse.data.success) {
          mcqResponse.data.data.forEach((attempt) => {
            if (
              attempt.selected_options &&
              attempt.selected_options.length > 0
            ) {
              attemptedQuestions[attempt.question_id] = true;
            }
          });
        }

        // Fetch coding attempts for all coding questions
        const codingAttempts = await Promise.all(
          questions
            .filter((q) => q.type === "coding")
            .map(async (question) => {
              try {
                const response = await axios.post(
                  `${import.meta.env.VITE_BASE_URL}/get-code`,
                  {
                    test_id: testId,
                    email: email,
                    question_id: question.question_id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );

                // Mark as attempted if code exists and is not empty/default
                if (
                  response.status === 200 &&
                  response.data.code &&
                  response.data.code.trim() &&
                  response.data.code !==
                    "#Before writing code first select language and remove this line"
                ) {
                  attemptedQuestions[question.question_id] = true;
                }
              } catch (error) {
                console.error(
                  `Error fetching code for question ${question.question_id}:`,
                  error
                );
              }
            })
        );

        setAnsweredQuestions(attemptedQuestions);
      } catch (error) {
        console.error("Error fetching previous attempts:", error);
      }
    };

    if (currentSection && questions.length > 0) {
      fetchPreviousAttempts();
    }
  }, [currentSection, questions]);

  useEffect(() => {
    let startTime = Date.now();
    const timer = setInterval(() => {
      setSectionTimeRemaining((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          handleSectionTimeout();
          return 0;
        }

        const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
        const newTime = prev - 1;

        // Store timing data
        localStorage.setItem("currentSectionDuration", newTime.toString());
        localStorage.setItem("sectionLastUpdated", Date.now().toString());

        // Calculate percentage for UI updates
        const totalDuration = currentSection?.sectionDuration * 60;
        const percentage = (newTime / totalDuration) * 100;

        // Show notifications at specific thresholds
        if (newTime === 600) {
          // 10 minutes
          toast.warning("10 minutes remaining!", {
            autoClose: 5000,
            hideProgressBar: false,
          });
        } else if (newTime === 300) {
          // 5 minutes
          toast.error("Only 5 minutes remaining!", {
            autoClose: 5000,
            hideProgressBar: false,
          });
        }

        // Update timer color based on remaining time
        const timerElement = document.querySelector(".timer-display");
        if (timerElement) {
          if (percentage <= 25) {
            timerElement.style.color = "#ef4444"; // red
            timerElement.style.backgroundColor = "#fee2e2";
          } else if (percentage <= 50) {
            timerElement.style.color = "#f97316"; // orange
            timerElement.style.backgroundColor = "#ffedd5";
          }
        }

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [currentSection]);

  useEffect(() => {
    // Add section timeout handler
    if (sectionTimeRemaining <= 0) {
      handleSectionTimeout();
    }
  }, [sectionTimeRemaining]);

  const handleSectionTimeout = async () => {
    // Clean up section timing data
    localStorage.removeItem("currentSectionStartTime");
    localStorage.removeItem("currentSectionDuration");
    localStorage.removeItem("currentSectionId");
    localStorage.removeItem("sectionLastUpdated");

    await handleSectionSubmit();
  };

  const handleSectionSubmit = async () => {
    try {
      const testInfo = JSON.parse(localStorage.getItem("testInfo"));
      const currentDate = new Date();
      const submittedAt = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      await axios.post(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/participant/test/save-section-submission-records`,
        {
          testId: testInfo.testId,
          sectionId: currentSection.sectionId,
          participantId: testInfo.participantId,
          submittedAt: submittedAt,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Clean up ALL section-related data
      const keysToRemove = [
        "currentSectionStartTime",
        "currentSectionDuration",
        "currentSectionId",
        "sectionLastUpdated",
        "currentQuestionIndex",
        "sectionAnswers",
      ];

      keysToRemove.forEach((key) => localStorage.removeItem(key));

      navigate("/section-selection");
    } catch (error) {
      console.error("Error submitting section:", error);
      toast.error("Failed to submit section");
    }
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  const handleQuestionSelect = (newQuestion) => {
    if (!isFullScreen) return; // Simply return if not in fullscreen

    setCurrentQuestion(newQuestion);
  };

  const markQuestionAsAnswered = (questionId) => {
    setAnsweredQuestions((prev) => ({
      ...prev,
      [questionId]: true,
    }));
  };

  const handleAnswerSubmit = (questionId) => {
    markQuestionAsAnswered(questionId);
    // const currentIndex = questions.findIndex(
    //   (q) => q.mcq_question_id === questionId || q.question_id === questionId
    // );
    // if (currentIndex < questions.length - 1) {
    //   handleQuestionSelect({
    //     type: questions[currentIndex + 1].type,
    //     index: currentIndex + 1,
    //   });
    // }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Typography variant="h5">Loading...</Typography>
      </div>
    );
  }

  const currentQuestionData = questions[currentQuestion.index];

  if (!currentQuestionData) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Typography variant="h5">No questions found</Typography>
      </div>
    );
  }

  return (
    <>
    <ProctorVideoWrapper hideVideo={true} />
      <Security />
      <SecurityProvider>
      <FullScreenManager>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" color="primary">
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <AssignmentIcon />
                <Typography variant="h6" >{testInfo?.testTitle}</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PersonIcon sx={{ mr: 1 }} />
                  <Typography>{localStorage.getItem("email")}</Typography>
                </Box>

                <Typography variant="h6" sx={{ color: "white" }}>
                  {currentSection?.sectionName}
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "rgba(255,255,255,0.1)",
                      px: 2,
                      py: 1,
                      borderRadius: 1,
                    }}
                  >
                    <TimerIcon sx={{ mr: 1 }} />
                    <Typography
                      className="timer-display"
                      sx={{ color: "white" }}
                    >
                      {formatTime(sectionTimeRemaining)}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleSectionSubmit}
                    sx={{ textTransform: "none" }}
                  >
                    Submit Section
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>

          <div style={getContentStyle(isNavOpen)}>
            <Box className="flex-1 p-4">
              {currentQuestionData.type === "mcq" ? (
                <MCQView
                  question={currentQuestionData}
                  currentQuestionNumber={currentQuestion.index + 1}
                  totalQuestions={questions.length}
                  onAnswerSubmit={(selectedOptions) =>
                    handleAnswerSubmit(currentQuestionData.mcq_question_id)
                  }
                  onNavigate={(direction) => {
                    if (!isFullScreen) return;
                    if (
                      direction === "next" &&
                      currentQuestion.index < questions.length - 1
                    ) {
                      handleQuestionSelect({
                        type: currentQuestion.type,
                        index: currentQuestion.index + 1,
                      });
                    } else if (
                      direction === "previous" &&
                      currentQuestion.index > 0
                    ) {
                      handleQuestionSelect({
                        type: currentQuestion.type,
                        index: currentQuestion.index - 1,
                      });
                    }
                  }}
                />
              ) : (
                <CodingView
                  question={currentQuestionData}
                  language={languages[0]}
                  languages={languages}
                  currentQuestionNumber={currentQuestion.index + 1}
                  totalQuestions={questions.length}
                  onCodeSubmit={() =>
                    handleAnswerSubmit(currentQuestionData.question_id)
                  }
                  onNavigate={(direction) => {
                    if (!isFullScreen) return;
                    if (
                      direction === "next" &&
                      currentQuestion.index < questions.length - 1
                    ) {
                      handleQuestionSelect({
                        type: questions[currentQuestion.index + 1].type,
                        index: currentQuestion.index + 1,
                      });
                    } else if (
                      direction === "previous" &&
                      currentQuestion.index > 0
                    ) {
                      handleQuestionSelect({
                        type: questions[currentQuestion.index - 1].type,
                        index: currentQuestion.index - 1,
                      });
                    }
                  }}
                />
              )}
            </Box>
          </div>

          <QuestionNavigation
            questions={questions}
            currentQuestion={currentQuestion}
            onQuestionSelect={handleQuestionSelect}
            isOpen={isNavOpen}
            onToggle={() => setIsNavOpen(!isNavOpen)}
            answeredQuestions={answeredQuestions}
            disabled={!isFullScreen}
          />
        </Box>
      </FullScreenManager>
      </SecurityProvider>
    </>
  );
};

export default CombineTestPage;
