import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Button,
  Divider,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Timer as TimerIcon,
  Code as CodeIcon,
  QuestionAnswer as MCQIcon,
  Info as InfoIcon,
  ArrowForward as ArrowForwardIcon,
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  Badge as BadgeIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import FullScreenManager from "./FullScreenManager";
import { ProctorContext } from "./TestProctor/ProctorContext";
import ProctorVideoWrapper from "./TestProctor/ProctorVideoWrapper";
import { useTestSubmission } from './TestProctor/TestSubmissionContext';
import { SecurityProvider, useSecurity } from './TestProctor/SecurityProviderContext';

const CombineSectionSelection = () => {
  const { submitTest } = useTestSubmission();
  const { violations } = useSecurity();
  const { startProctoring } = useContext(ProctorContext);
  const { stopProctoring } = useContext(ProctorContext);
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const [testData, setTestData] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  // const [remainingTime, setRemainingTime] = useState(0);
  const [countdownToStart, setCountdownToStart] = useState(120); // relaxation time 2 minutes
  const [loading, setLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState(() => {
    const savedTime = localStorage.getItem("testRemainingTime");
    const lastUpdated = localStorage.getItem("testLastUpdated");

    if (savedTime && lastUpdated) {
      const elapsedSeconds = Math.floor(
        (Date.now() - parseInt(lastUpdated)) / 1000
      );
      return Math.max(0, parseInt(savedTime)); // Use stored time directly
    }
    return 0;
  });

  // useEffect(() => {
  //   const initializeProctoring = async () => {
  //     const pendingProctoring = sessionStorage.getItem("pendingProctoring");
  //     if (pendingProctoring === "true") {
  //       try {
  //         const testInfo = JSON.parse(localStorage.getItem("testInfo"));
  //         console.log("Initializing proctoring...", {
  //           testId: testInfo.testId,
  //           participantId: testInfo.participantId,
  //         });

  //         await startProctoring(testInfo.testId);
  //         setShowVideo(true);
  //         console.log("Proctoring started successfully");

  //         // Clear pending flag
  //         sessionStorage.removeItem("pendingProctoring");
  //       } catch (error) {
  //         console.error("Error initializing proctoring:", error);
  //         if (error.name === "NotAllowedError") {
  //           toast.error("Camera access is required for this test");
  //           // Optionally redirect back to instructions
  //           navigate("/instruction");
  //         } else {
  //           // toast.error("Failed to initialize proctoring");
  //           console.error("Failed to initialize proctoring");
  //         }
  //       }
  //     }
  //   };

  //   initializeProctoring();
  // }, [startProctoring, navigate]);

  // Fetch test data
  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const testId = localStorage.getItem("testId");
        const testInfo = JSON.parse(localStorage.getItem("testInfo"));

        // Fetch test data
        const testResponse = await axios.get(
          `${BASE_URL}/api/org/tests/get-test-data/${testId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (testResponse.data.success) {
          // First, sort sections by orderNo
          const sortedSections = [...testResponse.data.data.sections].sort(
            (a, b) => a.sectionOrderNo - b.sectionOrderNo
          );

          // Fetch section completion status
          try {
            const completionResponse = await axios.get(
              `${BASE_URL}/api/participant/test/fetch-section-submission-records`,
              {
                params: {
                  testId: testId,
                  participantId: testInfo.participantId,
                },
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            // Create a map of completed sections
            const completedSections = new Set(
              completionResponse.data.data?.map(
                (record) => record.section_id
              ) || []
            );

            // Mark sections as completed based on the completion data
            const sectionsWithCompletionStatus = sortedSections.map(
              (section) => ({
                ...section,
                isCompleted: completedSections.has(section.sectionId),
              })
            );

            const testDataWithCompletedSections = {
              ...testResponse.data.data,
              sections: sectionsWithCompletionStatus,
            };

            setTestData(testDataWithCompletedSections);

            // Calculate and set remaining time only if not already stored
            const savedTime = localStorage.getItem("testRemainingTime");
            if (!savedTime) {
              const totalSectionDuration = sortedSections.reduce(
                (total, section) => total + section.sectionDuration,
                0
              );
              const totalTestDuration = totalSectionDuration + 10; // Adding 10 minutes buffer
              const totalSeconds = totalTestDuration * 60;

              // Store test start time if not already stored
              if (!localStorage.getItem("testStartTime")) {
                localStorage.setItem("testStartTime", Date.now().toString());
              }

              setRemainingTime(totalSeconds);
              localStorage.setItem(
                "testRemainingTime",
                totalSeconds.toString()
              );
            } else {
              // Calculate remaining time based on stored start time and duration
              const testStartTime = parseInt(
                localStorage.getItem("testStartTime")
              );
              const totalDuration = parseInt(savedTime);
              const elapsedSeconds = Math.floor(
                (Date.now() - testStartTime) / 1000
              );
              const remainingSeconds = Math.max(
                0,
                totalDuration - elapsedSeconds
              );

              setRemainingTime(remainingSeconds);
              localStorage.setItem(
                "testRemainingTime",
                remainingSeconds.toString()
              );
            }

            // Always select the first incomplete section by default
            const firstIncompleteSection = sectionsWithCompletionStatus.find(
              (section) => !section.isCompleted
            );
            if (firstIncompleteSection) {
              setSelectedSection(firstIncompleteSection);
            }
          } catch (completionError) {
            // If 404 or other error, proceed with original sections without completion status
            const testDataWithSortedSections = {
              ...testResponse.data.data,
              sections: sortedSections.map((section) => ({
                ...section,
                isCompleted: false,
              })),
            };

            setTestData(testDataWithSortedSections);

            // Calculate duration here too if not already stored
            const savedTime = localStorage.getItem("testRemainingTime");
            if (!savedTime) {
              const totalSectionDuration = sortedSections.reduce(
                (total, section) => total + section.sectionDuration,
                0
              );
              const totalTestDuration = totalSectionDuration + 10;
              const totalSeconds = totalTestDuration * 60;

              if (!localStorage.getItem("testStartTime")) {
                localStorage.setItem("testStartTime", Date.now().toString());
              }

              setRemainingTime(totalSeconds);
              localStorage.setItem(
                "testRemainingTime",
                totalSeconds.toString()
              );
            }

            // Apply original section selection logic
            if (!testDataWithSortedSections.allowCandidateSectionSelection) {
              setSelectedSection(sortedSections[0]);
            }
            // Always select the first section in error case
            if (sortedSections.length > 0) {
              setSelectedSection(sortedSections[0]);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching test data:", error);
        // toast.error("Failed to load test details");
      } finally {
        setLoading(false);
      }
    };

    fetchTestData();
  }, []);

  // Update handleSectionSelect to maintain original behavior
  const handleSectionSelect = (section) => {
    if (section.isCompleted) {
      return; // Don't allow selecting completed sections
    }

    if (testData.allowCandidateSectionSelection) {
      setSelectedSection(section);
    } else {
      // Find the index of the last completed section
      const lastCompletedIndex =
        testData.sections.findIndex((s) => !s.isCompleted) - 1;

      // Find the index of the section being selected
      const selectedIndex = testData.sections.findIndex(
        (s) => s.sectionId === section.sectionId
      );

      // Allow selection only if it's the next section in sequence
      if (selectedIndex === lastCompletedIndex + 1) {
        setSelectedSection(section);
      } else {
        toast.info(
          "Sections must be completed in sequence. Complete the current section to proceed."
        );
      }
    }
  };

  // Calculate question counts for a section
  const calculateQuestionCounts = (section) => {
    let mcqCount = 0;
    let codingCount = 0;

    // Count questions from groups
    section.groupIds.forEach((group) => {
      group.questions.forEach((question) => {
        if (question.type === "mcq") mcqCount++;
        if (question.type === "coding") codingCount++;
      });
    });

    // Count direct questions
    mcqCount += section.mcqQuestionIds.length;
    codingCount += section.codingQuestionIds.length;

    return { mcqCount, codingCount, total: mcqCount + codingCount };
  };

  useEffect(() => {
    let startTime = Date.now();
    const timer = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          submitTest("TIMEOUT");
          return 0;
        }

        const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
        const newTime = prev - 1;

        // Store timing data
        localStorage.setItem("testRemainingTime", newTime.toString());
        localStorage.setItem("testLastUpdated", Date.now().toString());

        // Calculate percentage for UI updates
        const totalDuration = testData?.duration * 60;
        const percentage = (newTime / totalDuration) * 100;

        // Show notifications at specific thresholds
        if (newTime === 600) {
          toast.warning("10 minutes remaining in test!");
        } else if (newTime === 300) {
          toast.error("Only 5 minutes remaining in test!");
        }

        // Update timer color
        const timerElement = document.querySelector(".test-timer");
        if (timerElement) {
          if (percentage <= 25) {
            timerElement.style.color = "#ef4444";
            timerElement.style.backgroundColor = "#fee2e2";
          } else if (percentage <= 50) {
            timerElement.style.color = "#f97316";
            timerElement.style.backgroundColor = "#ffedd5";
          }
        }

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [testData]);

  useEffect(() => {
    // Timer for section start countdown, not resetting on selection change
    const timer = setInterval(() => {
      setCountdownToStart((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          if (selectedSection) {
            handleStartSection();
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [selectedSection]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  const handleStartSection = () => {
    if (!selectedSection) {
      toast.warning("No section selected");
      return;
    }

    const currentTime = Date.now();
    const sectionDuration = selectedSection.sectionDuration * 60; // in seconds

    localStorage.setItem("currentSectionStartTime", currentTime.toString());
    localStorage.setItem("currentSectionDuration", sectionDuration.toString());
    localStorage.setItem("currentSectionId", selectedSection.sectionId);
    localStorage.setItem("sectionLastUpdated", currentTime.toString());

    // Prepare the necessary data to pass
    const sectionData = {
      testInfo: {
        testId: testData.testId,
        testTitle: testData.testTitle,
        testDescription: testData.testDescription,
        duration: testData.duration,
        allowCandidateSectionSelection: testData.allowCandidateSectionSelection,
      },
      sectionInfo: {
        sectionId: selectedSection.sectionId,
        sectionName: selectedSection.sectionName,
        sectionDuration: selectedSection.sectionDuration,
        allowQuestionNavigation: selectedSection.allowQuestionNavigation,
        allowShuffleQuestion: selectedSection.allowShuffleQuestion,
        allowedLanguages: selectedSection.allowedLanguages,
        questionStructure: {
          groupQuestions: selectedSection.groupIds.map((group) => ({
            groupId: group.groupId,
            questions: group.questions,
          })),
          mcqQuestions: selectedSection.mcqQuestionIds,
          codingQuestions: selectedSection.codingQuestionIds,
        },
      },
    };

    // Use React Router's navigate with state
    navigate("/test-section", {
      state: { sectionData },
      replace: true, // Prevents going back to section selection
    });
  };

  if (loading) {
    return (
      <Container sx={{ py: 4, textAlign: "center" }}>
        <Typography>Loading test details...</Typography>
      </Container>
    );
  }

  if (!testData) {
    return (
      <Container sx={{ py: 4, textAlign: "center" }}>
        <Typography color="error">Failed to load test details</Typography>
      </Container>
    );
  }

  return (
    <>
    
      <ProctorVideoWrapper
        hideVideo={true}
      />
      <SecurityProvider>
      <FullScreenManager>
        <Container maxWidth="xl" sx={{ py: 2 }}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 4,
              height: "auto",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 2.5,
                flex: 2,
                mr: 2,
                background: "linear-gradient(to right, #2196f3, #1976d2)",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Box sx={{ color: "white", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <AssignmentIcon sx={{ fontSize: 28, mr: 1 }} />
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    {testData.testTitle}
                  </Typography>
                </Box>

                <Box sx={{ ml: 0.5 }}>
                  {testData.testDescription && (
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
                    >
                      {/* <DescriptionIcon sx={{ fontSize: 20, mr: 1 }} /> */}
                      <Typography variant="body1">
                        {testData.testDescription}
                      </Typography>
                    </Box>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                    <BadgeIcon sx={{ fontSize: 20, mr: 1 }} />
                    <Typography variant="body1">
                      Test ID: {testData.testId}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PersonIcon sx={{ fontSize: 20, mr: 1 }} />
                    <Typography variant="body1">
                      {localStorage.getItem("email")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>

            <Paper
              elevation={3}
              sx={{
                p: 2.5,
                textAlign: "center",
                flex: 1,
                background: "linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                <TimerIcon sx={{ color: "white", mr: 1 }} />
                <Typography variant="h6" sx={{ color: "white" }}>
                  Time Remaining
                </Typography>
              </Box>
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "monospace",
                  color: "white",
                  textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                  mb: 1,
                }}
              >
                {formatTime(remainingTime)}
              </Typography>
              <Button
                variant="contained"
                color="error"
                size="medium"
                onClick={() => submitTest("MANUAL")}
                // startIcon={<ExitToAppIcon />}
                sx={{ alignSelf: "center" }}
              >
                Submit Test
              </Button>
            </Paper>
          </Box>

          <Grid container spacing={4}>
            {/* Left Panel - Section Details */}
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
                {selectedSection ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {/* Section Title */}
                    <Typography variant="h5" gutterBottom color="primary">
                      {selectedSection.sectionName}
                    </Typography>
                    <Divider sx={{ my: 2 }} />

                    {/* Questions Overview */}
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="h6" gutterBottom>
                        Questions Overview
                      </Typography>
                      {(() => {
                        const counts = calculateQuestionCounts(selectedSection);
                        return (
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Paper
                                elevation={2}
                                sx={{
                                  p: 2,
                                  textAlign: "center",
                                  bgcolor: "primary.light",
                                }}
                              >
                                <Typography variant="subtitle2" color="white">
                                  Duration
                                </Typography>
                                <Typography variant="h6" color="white">
                                  {selectedSection.sectionDuration}m
                                </Typography>
                              </Paper>
                            </Grid>
                            <Grid item xs={8}>
                              <Paper
                                elevation={2}
                                sx={{
                                  p: 2,
                                  textAlign: "center",
                                  bgcolor: "secondary.light",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      variant="subtitle2"
                                      color="white"
                                    >
                                      MCQ
                                    </Typography>
                                    <Typography variant="h6" color="white">
                                      {counts.mcqCount}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="subtitle2"
                                      color="white"
                                    >
                                      Coding
                                    </Typography>
                                    <Typography variant="h6" color="white">
                                      {counts.codingCount}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="subtitle2"
                                      color="white"
                                    >
                                      Total
                                    </Typography>
                                    <Typography variant="h6" color="white">
                                      {counts.total}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                          </Grid>
                        );
                      })()}
                    </Box>

                    {/* Programming Languages */}
                    {selectedSection.allowedLanguages &&
                      selectedSection.allowedLanguages.length > 0 && (
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="h6" gutterBottom>
                            Allowed Programming Languages
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                          >
                            {selectedSection.allowedLanguages.map(
                              (lang, index) => (
                                <Chip
                                  key={index}
                                  icon={<CodeIcon />}
                                  label={lang.language}
                                  color="primary"
                                  variant="outlined"
                                />
                              )
                            )}
                          </Box>
                        </Box>
                      )}

                    {/* Navigation Rules */}
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="h6" gutterBottom>
                        Test Rules & Navigation
                      </Typography>
                      <Box
                        sx={{ bgcolor: "info.light", p: 2, borderRadius: 1 }}
                      >
                        <Typography
                          variant="body2"
                          color="info.contrastText"
                          paragraph
                        >
                          {testData.allowCandidateSectionSelection
                            ? "You can attempt sections in any order of your choice."
                            : "Sections must be attempted in sequence. Complete current section before moving to next."}
                        </Typography>
                        <Typography variant="body2" color="info.contrastText">
                          {selectedSection.allowQuestionNavigation
                            ? "Within this section, you can navigate freely between questions."
                            : "Questions in this section must be answered in sequence."}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Important Notes */}
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        Important Notes
                      </Typography>
                      <Box
                        sx={{ bgcolor: "warning.light", p: 2, borderRadius: 1 }}
                      >
                        <Typography
                          variant="body2"
                          color="warning.contrastText"
                        >
                          • Submit each section separately
                        </Typography>
                        <Typography
                          variant="body2"
                          color="warning.contrastText"
                        >
                          • Time limit is strictly enforced
                        </Typography>
                        <Typography
                          variant="body2"
                          color="warning.contrastText"
                        >
                          • Save your answers regularly
                        </Typography>
                      </Box>
                    </Box>
                  </motion.div>
                ) : (
                  <Box sx={{ textAlign: "center", color: "text.secondary" }}>
                    <InfoIcon sx={{ fontSize: 48, mb: 2 }} />
                    <Typography>Select a section to view details</Typography>
                  </Box>
                )}
              </Paper>
            </Grid>

            {/* Right Panel - Sections */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                {testData.sections.map((section) => (
                  <Grid item xs={12} sm={6} md={4} key={section.sectionId}>
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <Card
                        elevation={
                          selectedSection?.sectionId === section.sectionId
                            ? 8
                            : 2
                        }
                        sx={{
                          cursor:
                            testData.allowCandidateSectionSelection &&
                            !section.isCompleted
                              ? "pointer"
                              : "default",
                          border:
                            selectedSection?.sectionId === section.sectionId
                              ? 2
                              : 0,
                          borderColor: "primary.main",
                          opacity: section.isCompleted ? 0.5 : 1,
                          position: "relative",
                          pointerEvents: section.isCompleted ? "none" : "auto",
                        }}
                        onClick={() =>
                          !section.isCompleted && handleSectionSelect(section)
                        }
                      >
                        {section.isCompleted && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                              bgcolor: "success.main",
                              color: "white",
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              fontSize: "0.75rem",
                            }}
                          >
                            Completed
                          </Box>
                        )}
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {section.sectionName}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <TimerIcon sx={{ mr: 1 }} />
                            <Typography>
                              {section.sectionDuration} minutes
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              {calculateQuestionCounts(section).total} Questions
                            </Typography>
                            <Tooltip title="View Details">
                              <IconButton size="small" color="primary">
                                {/* <ArrowForwardIcon /> */}
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </CardContent>
                      </Card>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* Bottom Start Section Button */}
          {selectedSection && (
            <Box
              sx={{
                mt: 4,
                textAlign: "center",
                position: "fixed",
                bottom: 16,
                right: 10,
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  mx: "auto",
                  maxWidth: 400,
                  background:
                    "linear-gradient(135deg, #4caf50 0%, #2e7d32 100%)",
                }}
              >
                <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
                  Section will start in: {Math.floor(countdownToStart / 60)}:
                  {(countdownToStart % 60).toString().padStart(2, "0")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleStartSection}
                  sx={{
                    bgcolor: "white",
                    color: "primary.main",
                    "&:hover": {
                      bgcolor: "grey.100",
                    },
                  }}
                >
                  Start Section Now
                </Button>
              </Paper>
            </Box>
          )}
        </Container>
      </FullScreenManager>
      </SecurityProvider>
    </>
  );
};

export default CombineSectionSelection;
