import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Modal,
  Chip,
  Fade,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import {
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Videocam as VideocamIcon,
  DesktopWindows as DesktopIcon,
  Circle as CircleIcon,
  Fullscreen as FullscreenIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import io from "socket.io-client";
import axios from "axios";
import { toast } from "react-toastify";

const ProctorView = ({ testId, onClose }) => {
  const theme = useTheme();
  const [activeSessions, setActiveSessions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [streams, setStreams] = useState({});
  const [candidateEmails, setCandidateEmails] = useState({});
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [modalView, setModalView] = useState(0);

  const videoRefs = useRef({});
  const peerConnections = useRef({});
  const socketRef = useRef();
  const mountedRef = useRef(true);
  const intervalRef = useRef();

  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const [isReconnecting, setIsReconnecting] = useState(false);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 5;

  const peerConnectionConfig = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      { urls: "stun:stun1.l.google.com:19302" },
      {
        urls: "turn:numb.viagenie.ca",
        username: "webrtc@live.com",
        credential: "muazkh",
      },
    ],
  };

  const fetchActiveSessions = useCallback(async () => {
    if (!mountedRef.current) return;

    try {
      const response = await axios.get(
        `${BASE_URL}/proctor/active-sessions/${testId}`
      );
      if (response.data.success && mountedRef.current) {
        setActiveSessions(response.data.sessions);
        setError(null);
      }
    } catch (err) {
      console.error("Error fetching sessions:", err);
      if (mountedRef.current) {
        setError("Failed to load active sessions");
      }
    }
  }, [testId, BASE_URL]);

  const handleTrack = useCallback((event, candidateId) => {
    if (!mountedRef.current) return;

    const [stream] = event.streams;
    if (stream) {
      setStreams((prev) => {
        const existingStream = prev[candidateId];
        if (existingStream) {
          // Update existing stream with new track
          const newStream = new MediaStream([
            ...existingStream.getTracks(),
            event.track,
          ]);
          return {
            ...prev,
            [candidateId]: newStream,
          };
        }
        return {
          ...prev,
          [candidateId]: stream,
        };
      });
    }
  }, []);

  const cleanupConnection = useCallback((candidateId) => {
    const pc = peerConnections.current[candidateId];
    if (pc) {
      pc.close();
      delete peerConnections.current[candidateId];
    }

    if (videoRefs.current[candidateId]) {
      const video = videoRefs.current[candidateId];
      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => track.stop());
        video.srcObject = null;
      }
      delete videoRefs.current[candidateId];
    }

    setStreams((prev) => {
      const newStreams = { ...prev };
      delete newStreams[candidateId];
      return newStreams;
    });
  }, []);

  const createPeerConnection = useCallback(
    async (candidateId) => {
      try {
        if (peerConnections.current[candidateId]) {
          await cleanupConnection(candidateId);
        }

        const pc = new RTCPeerConnection(peerConnectionConfig);

        pc.ontrack = (event) => handleTrack(event, candidateId);

        pc.onicecandidate = (event) => {
          if (event.candidate && socketRef.current?.connected) {
            socketRef.current.emit("ice-candidate", {
              targetId: candidateId,
              candidate: event.candidate,
            });
          }
        };

        pc.onconnectionstatechange = () => {
          console.log(
            `Connection state for ${candidateId}:`,
            pc.connectionState
          );

          if (
            pc.connectionState === "failed" ||
            pc.connectionState === "disconnected"
          ) {
            handleConnectionFailure(candidateId);
          }
        };

        peerConnections.current[candidateId] = pc;
        return pc;
      } catch (error) {
        console.error("Error creating peer connection:", error);
        throw error;
      }
    },
    [handleTrack, cleanupConnection]
  );

  // New function to handle connection failures
  const handleConnectionFailure = useCallback(
    async (candidateId) => {
      if (reconnectAttemptsRef.current >= MAX_RECONNECT_ATTEMPTS) {
        console.log(`Max reconnection attempts reached for ${candidateId}`);
        cleanupConnection(candidateId);
        return;
      }

      setIsReconnecting(true);
      reconnectAttemptsRef.current++;

      try {
        // Request new offer from candidate
        socketRef.current?.emit("request-reoffer", { candidateId });

        // Set timeout for reconnection
        reconnectTimeoutRef.current = setTimeout(() => {
          if (mountedRef.current) {
            handleConnectionFailure(candidateId);
          }
        }, 5000);
      } catch (error) {
        console.error("Reconnection failed:", error);
        cleanupConnection(candidateId);
      }
    },
    [cleanupConnection]
  );

  // Modified socket initialization
  const initializeSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    socketRef.current = io(BASE_URL, {
      path: "/socket.io", // Add explicit path
      transports: ["websocket", "polling"],
      reconnection: true,
      reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
      reconnectionDelay: 1000,
      query: { testId, role: "admin" },
      cors: {
        origin: [
          "https://staging.cirruscypher.com",
          "https://www.cirruscypher.com",
          "https://api-staging.cirruscypher.com",
          "https://api.cirruscypher.com",
        ],
        methods: ["GET", "POST"],
      },
    });

    socketRef.current.on("connect", () => {
      console.log("Admin connected to socket");
      socketRef.current.emit("register", { role: "admin", testId });

      // Request current state on reconnection
      socketRef.current.emit("request-current-state", { testId });
    });

    socketRef.current.on(
      "new-candidate",
      async ({ candidateId, sdp, email }) => {
        try {
          const pc = await createPeerConnection(candidateId);
          setCandidateEmails((prev) => ({ ...prev, [candidateId]: email }));
          await pc.setRemoteDescription(new RTCSessionDescription(sdp));
          const answer = await pc.createAnswer();
          await pc.setLocalDescription(answer);
          socketRef.current.emit("answer", { candidateId, sdp: answer });
        } catch (error) {
          console.error("Error handling new candidate:", error);
          // toast.error("Failed to connect to candidate stream");
        }
      }
    );

    socketRef.current.on("ice-candidate", async ({ candidate }) => {
      try {
        Object.values(peerConnections.current).forEach(async (pc) => {
          if (pc.remoteDescription) {
            await pc.addIceCandidate(new RTCIceCandidate(candidate));
          }
        });
      } catch (error) {
        console.error("Error adding ICE candidate:", error);
      }
    });

    socketRef.current.on("candidate-disconnected", ({ candidateId }) => {
      cleanupConnection(candidateId);
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socketRef.current.on("reoffer", async ({ candidateId, sdp }) => {
      try {
        const pc = await createPeerConnection(candidateId);
        await pc.setRemoteDescription(new RTCSessionDescription(sdp));
        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);
        socketRef.current.emit("answer", { candidateId, sdp: answer });

        setIsReconnecting(false);
        reconnectAttemptsRef.current = 0;
      } catch (error) {
        console.error("Error handling reoffer:", error);
      }
    });

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [testId, createPeerConnection, cleanupConnection]);

  useEffect(() => {
    mountedRef.current = true;

    // Initial fetch and socket setup
    fetchActiveSessions();
    const cleanup = initializeSocket();

    // Set up interval for fetching active sessions
    intervalRef.current = setInterval(fetchActiveSessions, 30000);

    return () => {
      mountedRef.current = false;
      clearInterval(intervalRef.current);
      cleanup();

      // Cleanup all connections
      Object.keys(peerConnections.current).forEach(cleanupConnection);
    };
  }, [fetchActiveSessions, initializeSocket, cleanupConnection]);

  const ParticipantCard = ({ stream, candidateId, email, onClick }) => {
    const videoRef = useRef();
    const screenRef = useRef();
    const playAttempts = useRef(0);
    const maxPlayAttempts = 5;
    const playTimeoutRef = useRef();

    const playVideo = async (videoElement, mediaStream) => {
      if (
        !videoElement ||
        !mediaStream ||
        playAttempts.current >= maxPlayAttempts
      )
        return;
      try {
        await videoElement.play();
        playAttempts.current = 0;
      } catch (error) {
        console.error("Error playing video:", error);
        playAttempts.current++;
        if (playAttempts.current < maxPlayAttempts) {
          playTimeoutRef.current = setTimeout(
            () => playVideo(videoElement, mediaStream),
            1000
          );
        }
      }
    };

    useEffect(() => {
      if (stream && videoRef.current && screenRef.current) {
        const videoTracks = stream.getVideoTracks();
        if (videoTracks.length >= 2) {
          const cameraStream = new MediaStream([videoTracks[0]]);
          const screenStream = new MediaStream([videoTracks[1]]);

          videoRef.current.srcObject = cameraStream;
          screenRef.current.srcObject = screenStream;

          playVideo(videoRef.current, cameraStream);
          playVideo(screenRef.current, screenStream);
        }
      }

      return () => {
        clearTimeout(playTimeoutRef.current);
        if (videoRef.current?.srcObject) {
          videoRef.current.srcObject = null;
        }
        if (screenRef.current?.srcObject) {
          screenRef.current.srcObject = null;
        }
      };
    }, [stream]);

    return (
      <motion.div whileHover={{ scale: 1.02 }} transition={{ duration: 0.2 }}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            height: "100%",
            bgcolor: "rgba(26, 26, 26, 0.95)",
            cursor: "pointer",
            "&:hover": {
              boxShadow: theme.shadows[10],
            },
            borderRadius: 2,
          }}
          onClick={onClick}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <CircleIcon sx={{ color: "#4caf50", fontSize: 12, mr: 1 }} />
            <Typography
              variant="subtitle1"
              sx={{ color: "white", flexGrow: 1, fontWeight: 500 }}
            >
              {email || "Unknown Candidate"}
            </Typography>
            <Chip
              size="small"
              label="Live"
              sx={{
                bgcolor: "#4caf50",
                color: "white",
                height: 20,
                fontWeight: 500,
              }}
            />
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "56.25%",
                  bgcolor: "black",
                  borderRadius: 1,
                  overflow: "hidden",
                }}
              >
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  muted
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    left: 8,
                    bgcolor: "rgba(0,0,0,0.6)",
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <VideocamIcon sx={{ color: "white", fontSize: 16 }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "56.25%",
                  bgcolor: "black",
                  borderRadius: 1,
                  overflow: "hidden",
                }}
              >
                <video
                  ref={screenRef}
                  autoPlay
                  playsInline
                  muted
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    left: 8,
                    bgcolor: "rgba(0,0,0,0.6)",
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <DesktopIcon sx={{ color: "white", fontSize: 16 }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </motion.div>
    );
  };

  const DetailModal = ({ open, onClose, stream, email }) => {
    const videoRef = useRef();
    const screenRef = useRef();
    const [isVideoReady, setIsVideoReady] = useState(false);

    useEffect(() => {
      if (!stream || !open) return;

      const setupVideo = async () => {
        try {
          const videoTracks = stream.getVideoTracks();
          console.log("Setting up video with tracks:", videoTracks.length);

          if (videoTracks.length >= 2) {
            // Camera feed
            const cameraStream = new MediaStream([videoTracks[0]]);
            if (videoRef.current) {
              videoRef.current.srcObject = cameraStream;
              try {
                await videoRef.current.play();
                console.log("Camera video playing");
              } catch (err) {
                console.error("Camera play error:", err);
              }
            }

            // Screen feed
            const screenStream = new MediaStream([videoTracks[1]]);
            if (screenRef.current) {
              screenRef.current.srcObject = screenStream;
              try {
                await screenRef.current.play();
                console.log("Screen video playing");
              } catch (err) {
                console.error("Screen play error:", err);
              }
            }

            setIsVideoReady(true);
          }
        } catch (error) {
          console.error("Setup error:", error);
        }
      };

      // Set a small delay to ensure DOM is ready
      const timeoutId = setTimeout(() => {
        setupVideo();
      }, 100);

      return () => {
        clearTimeout(timeoutId);
        if (videoRef.current) {
          const tracks = videoRef.current.srcObject?.getTracks() || [];
          tracks.forEach((track) => track.stop());
          videoRef.current.srcObject = null;
        }
        if (screenRef.current) {
          const tracks = screenRef.current.srcObject?.getTracks() || [];
          tracks.forEach((track) => track.stop());
          screenRef.current.srcObject = null;
        }
        setIsVideoReady(false);
      };
    }, [stream, open]);

    return (
      <Modal open={open} onClose={onClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90vw",
              maxWidth: 1400,
              maxHeight: "90vh",
              bgcolor: "rgba(26, 26, 26, 0.95)",
              borderRadius: 3,
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Box>
                <Typography variant="h5" sx={{ color: "white", mb: 1 }}>
                  Candidate Details
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CircleIcon sx={{ color: "#4caf50", fontSize: 12 }} />
                  <Typography variant="subtitle1" sx={{ color: "#4caf50" }}>
                    {email}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                onClick={onClose}
                sx={{
                  color: "white",
                  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Video Feeds */}
            <Grid container spacing={2} sx={{ height: "calc(90vh - 150px)" }}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    height: "100%",
                    bgcolor: "black",
                    borderRadius: 2,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      position: "absolute",
                      top: 16,
                      left: 16,
                      color: "white",
                      bgcolor: "rgba(0,0,0,0.6)",
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      zIndex: 1,
                    }}
                  >
                    <VideocamIcon sx={{ fontSize: 16 }} />
                    Camera Feed
                  </Typography>
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                  {!isVideoReady && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "rgba(0,0,0,0.7)",
                      }}
                    >
                      <Typography color="white">
                        Loading camera feed...
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    height: "100%",
                    bgcolor: "black",
                    borderRadius: 2,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      position: "absolute",
                      top: 16,
                      left: 16,
                      color: "white",
                      bgcolor: "rgba(0,0,0,0.6)",
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      zIndex: 1,
                    }}
                  >
                    <DesktopIcon sx={{ fontSize: 16 }} />
                    Screen Share
                  </Typography>
                  <video
                    ref={screenRef}
                    autoPlay
                    playsInline
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                  {!isVideoReady && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "rgba(0,0,0,0.7)",
                      }}
                    >
                      <Typography color="white">
                        Loading screen feed...
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    );
  };

  const handleRefresh = () => {
    fetchActiveSessions();
  };

  return (
    <Box
      sx={{
        p: 3,
        minHeight: "100vh",
        bgcolor: "#121212",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))",
      }}
    >
      <Paper
        elevation={24}
        sx={{
          p: 3,
          bgcolor: "rgba(26, 26, 26, 0.95)",
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box>
            <Typography variant="h4" sx={{ color: "white", mb: 1 }}>
              Live Proctoring Session
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "grey.500" }}>
              Test ID: {testId}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              onClick={handleRefresh}
              sx={{
                color: "white",
                "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
              }}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              onClick={onClose}
              sx={{
                color: "white",
                "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Grid container spacing={3}>
          {Object.entries(streams).map(([candidateId, stream]) => (
            <Grid item xs={12} sm={6} md={4} key={candidateId}>
              <ParticipantCard
                stream={stream}
                candidateId={candidateId}
                email={candidateEmails[candidateId]}
                onClick={() => {
                  // Only clone video tracks
                  const videoTracks = stream
                    .getTracks()
                    .filter((track) => track.kind === "video");
                  console.log("Found video tracks:", videoTracks.length);

                  if (videoTracks.length >= 2) {
                    const clonedStream = new MediaStream();
                    // Only add video tracks
                    videoTracks.forEach((track) => {
                      const clonedTrack = track.clone();
                      clonedStream.addTrack(clonedTrack);
                    });

                    console.log(
                      "Created cloned stream with tracks:",
                      clonedStream.getTracks().length
                    );
                    setSelectedParticipant({
                      stream: clonedStream,
                      email: candidateEmails[candidateId],
                    });
                  } else {
                    console.error("Not enough video tracks found");
                  }
                }}
              />
            </Grid>
          ))}
          {Object.keys(streams).length === 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: "center",
                  py: 8,
                  color: "grey.500",
                }}
              >
                <Typography variant="h6">
                  No active proctoring sessions
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Waiting for candidates to join...
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        <DetailModal
          open={!!selectedParticipant}
          onClose={() => setSelectedParticipant(null)}
          stream={selectedParticipant?.stream}
          email={selectedParticipant?.email}
        />
      </Paper>
    </Box>
  );
};

export default ProctorView;
