import React, { createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ProctorContext } from './ProctorContext';

export const TestSubmissionContext = createContext(null);

export const TestSubmissionProvider = ({ children }) => {
  const navigate = useNavigate();
  const { stopProctoring } = useContext(ProctorContext);

  const submitTest = useCallback(async (reason = 'MANUAL') => {
    try {
      const testInfo = JSON.parse(localStorage.getItem('testInfo'));
      
      // Stop proctoring first
      try {
        await stopProctoring(testInfo.testId);
        console.log('Proctoring stopped successfully');
      } catch (error) {
        console.error('Error stopping proctoring:', error);
      }

      // Submit test with reason
      await axios.post(
         `${import.meta.env.VITE_BASE_URL}/api/test/submit`,
        {
          email: localStorage.getItem("email"),
          testId: localStorage.getItem("testId"),
        //   submissionReason: reason
        }
      );

      // Clean up test-related data
      const keysToRemove = [
        "testRemainingTime",
        "testLastUpdated",
        "testStartTime",
        "currentSectionStartTime",
        "currentSectionDuration",
        "currentSectionId",
        "sectionLastUpdated",
        "email",
        "testId",
        "testInfo",
        "currentQuestionIndex",
        "sectionAnswers",
        "proctoringActive"
      ];

      keysToRemove.forEach((key) => localStorage.removeItem(key));

      // Show submission message based on reason
      const messages = {
        MANUAL: 'Test submitted successfully',
        TIMEOUT: 'Test time expired',
        FULLSCREEN_VIOLATION: 'Test submitted due to fullscreen violations',
        FACE_DETECTION_VIOLATION: 'Test submitted due to face detection violations',
        TAB_SWITCH_VIOLATION: 'Test submitted due to tab switching violations',
        COPY_PASTE_VIOLATION: 'Test submitted due to copy/paste violations',
        KEYBOARD_SHORTCUT_VIOLATION: 'Test submitted due to keyboard shortcut violations',
        RIGHT_CLICK_VIOLATION: 'Test submitted due to right-click violations'
      };

      toast.info(messages[reason] || 'Test submitted');
      navigate("/test-submitted");
    } catch (error) {
      console.error("Error submitting test:", error);
      toast.error("Failed to submit test");
    }
  }, [stopProctoring, navigate]);

  return (
    <TestSubmissionContext.Provider value={{ submitTest }}>
      {children}
    </TestSubmissionContext.Provider>
  );
};

// Custom hook for easy access
export const useTestSubmission = () => {
  const context = useContext(TestSubmissionContext);
  if (!context) {
    throw new Error('useTestSubmission must be used within a TestSubmissionProvider');
  }
  return context;
};